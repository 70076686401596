@font-face {
    font-family: 'BYekan';
    src: url("../assets/fonts/BYekan.ttf");
}
@font-face {
    font-family: 'Vazir';
    src: url("../assets/fonts/Vazir.ttf");
}

*{
  font-family: "Vazir" !important;  
}