.main-card {
  width: 665px;
  height: 330px;
  background-image: url("card.jpeg");
  background-size: cover;
  direction: rtl;
}
.title-card {
  text-align: center;
  font-weight: bold;
}
.line-card {
  color: orange;
  border: 2px solid orange;
  width: 150px;
}
.lib-card {
  color: orange;
}
.text-card {
  margin-right: 20px;
  margin-top: 10px;
  font-weight: bold;
}
